import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// import swalert from './sweet-alerts'

const baseUrl = process.env.VUE_APP_BASE_URL
if (!baseUrl) {
  throw new Error('baseUrl not defined src\\libs\\axios.js')
}

// const tokenStr = ''
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseUrl,
  // timeout: 1000,
  headers: { AuthToken: localStorage.getItem('token') },
})
axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 403) {
      axiosIns.defaults.headers.Authorization = null
      store.dispatch('auth/LOGOUT')
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
