import { Ability } from '@casl/ability'
// import { initialAbility } from './config'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = store.getters['auth/getPermission']

export default new Ability(userData)
