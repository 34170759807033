// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    products: [],
    cart_products: [],
    wishlist: [],
  },
  getters: {
    getProductsData(state) {
      const products = []
      const all = state.products.data.data
      all.forEach(element => {
        products.push({
          id: element.id, ...element.attributes, date: Date.now(), qty: 1, activeStatus: element.activeStatus,
        })
      })
      return products
    },
    getProperties(state) {
      return state.products.data.meta
    },
    getCartProducts(state) {
      return state.cart_products
    },
    getTotals(state) {
      const allPrice = state.cart_products.map(x => Number(x.list_price * x.qty))
      const totalPrice = allPrice.reduce((a, b) => a + b, 0)
      const allQty = state.cart_products.map(x => Number(x.qty))
      const totalQty = allQty.reduce((a, b) => a + b, 0)
      return {
        totalPrice,
        totalQty,
      }
    },
  },
  mutations: {
    SET_PRODUCTS(state, val) {
      state.products = val
    },
    SET_CART(state, { val, set }) {
      if (set) {
        state.cart_products.push(val)
      } else {
        const index = state.cart_products.findIndex(r => r.id === val.id)
        state.cart_products.splice(index, 1)
      }
    },
    SET_QTY(state, product) {
      const index = state.cart_products.findIndex(r => r.id === product.id)
      state.cart_products[index] = product
    },
    SET_LIST(state, val) {
      state.cart_products = val
    },
  },
  actions: {
    async getProducts({ commit, rootState }, queries) {
      const role = await rootState.auth.userInfo.role
      const res = await axiosIns.get(`parasut/product?${queries}&role=${role}`)
      await commit('SET_PRODUCTS', res)
    },
    setInvoce({ rootState, commit }) {
      const state = rootState.auth
      const selfState = rootState.product
      const date = new Date().toISOString().split('T')[0]
      const data = {
        item_type: 'estimate',
        description: 'panelden',
        issue_date: String(date),
        due_date: null,
        invoice_series: null,
        invoice_id: null,
        currency: 'TRL',
        exchange_rate: '1.0000',
        withoding_rate: '0.00',
        vat_withholding_rate: '0.00',
        invoice_discount_type: 'percentage',
        invoice_discount: '0.00',
        billing_address: '213',
        billing_phone: state.userInfo.parasut.phone,
        billing_fax: state.userInfo.parasut.fax,
        tax_office: state.userInfo.parasut.tax_office,
        tax_number: state.userInfo.parasut.tax_number,
        country: state.userInfo.parasut.country,
        city: state.userInfo.parasut.city,
        district: state.userInfo.parasut.district,
        is_abroad: false,
        order_no: null,
        order_date: String(date),
        shipment_address: state.userInfo.parasut.address,
        shipment_included: false,
        cash_sale: false,
        payment_account_id: null,
        payment_date: null,
        payment_description: null,
        customerId: state.userInfo.user.displayName,
        details: [],
      }
      selfState.cart_products.forEach(el => {
        const product = {
          unit_price: Number(el.list_price),
          quantity: Number(el.qty),
          discount_type: 'percentage',
          discount_value: '0.00',
          excise_duty_type: 'percentage',
          excise_duty_value: '0.00',
          description: 'panelden eklenen ürün',
          delivery_method: 'CFR',
          shipping_method: 'Denizyolu',
          productId: el.id,
        }
        data.details.push(product)
      })
      return axiosIns.post('/parasut/invoice', data).then(res => {
        if (res.status === 200) {
          commit('SET_LIST', [])
        }
        return res
      })
    },
    async setOrder({ rootState }, payload) {
      const selfState = rootState.product
      const body = {
        products: selfState.cart_products,
        user: { ...payload },
      }
      return axiosIns.post('invoice/order', body).then(res => res).catch(err => err)
    },
  },

}
