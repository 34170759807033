// eslint-disable-next-line import/no-cycle
import request from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customers: [],
  },
  getters: {
    tableData(state) {
      const arr = []
      state.customers.forEach(element => {
        arr.push({ id: element.id, ...element.attributes })
      })
      return arr
    },
  },
  mutations: {
    SET_CUSTOMERS(state, val) {
      state.customers = val
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async addCustomer({}, payload) {
      const res = await request.post('parasut/customer/', payload)
      return res
    },
    async getCustomers({ commit }) {
      // console.log(request.defaults.headers)
      const res = await request.get('parasut/customer')
      await commit('SET_CUSTOMERS', res.data.data)
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteCustomer({}, id) {
      const res = await request.delete(`parasut/customer/${id}`)
      return res
    },
  },
}
