import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/e-commerce-shop/ECommerceShop.vue'),
      meta: {
        pageTitle: 'Ürünler',
        breadcrumb: [
          {
            text: 'Ürünler',
            active: true,
          },
        ],
        action: 'all',
        resource: 'guest',
      },
    },
    {
      path: '/featured',
      name: 'featured',
      component: () => import('@/views/products/featured.vue'),
      meta: {
        pageTitle: 'Önce Çıkanlar',
        breadcrumb: [
          {
            text: 'Öne çıkan ürünler',
            active: true,
          },
        ],
        action: 'all',
        resource: 'featured',
        auth_require: true,
      },
    },
    {
      path: '/selected',
      name: 'selected',
      component: () => import('@/views/products/selected.vue'),
      meta: {
        pageTitle: 'Ürün seçimi',
        breadcrumb: [
          {
            text: 'Ürün seçimi',
            active: true,
          },
        ],
        action: 'all',
        resource: 'featured',
        auth_require: true,
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/e-commerce-checkout/ECommerceCheckout.vue'),
      meta: {
        pageTitle: 'Sepetim',
        breadcrumb: [
          {
            text: 'Sepetim',
            active: true,
          },
        ],
        action: 'all',
        resource: 'guest',
      },
    },
    {
      path: '/create-customer',
      name: 'customer-add',
      component: () => import('@/views/customer/create.vue'),
      meta: {
        pageTitle: 'Müşteri Ekle',
        breadcrumb: [
          {
            text: 'Müşteri Ekle',
            active: true,
          },
        ],
        action: 'all',
        resource: 'customer',
        auth_require: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/customer/profile.vue'),
      meta: {
        pageTitle: 'Profil',
        breadcrumb: [
          {
            text: 'Profil',
            active: true,
          },
        ],
        action: 'all',
        resource: 'profile',
        auth_require: true,
      },
    },
    {
      path: '/customer',
      name: 'customer-list',
      component: () => import('@/views/customer/index.vue'),
      meta: {
        pageTitle: 'Müşteri Listesi',
        breadcrumb: [
          {
            text: 'Müşteri Listesi',
            active: true,
          },
        ],
        action: 'all',
        resource: 'customer',
        auth_require: true,
      },
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: () => import('@/views/products/index.vue'),
      meta: {
        pageTitle: 'Ürün Listesi',
        breadcrumb: [
          {
            text: 'Ürün Listesi',
            active: true,
          },
        ],
        action: 'all',
        resource: 'customer',
        auth_require: true,
      },
    },
    {
      path: '/order-list',
      name: 'order-list',
      component: () => import('@/views/order/index.vue'),
      meta: {
        pageTitle: 'Sipariş Listesi',
        breadcrumb: [
          {
            text: 'Sipariş Listesi',
            active: true,
          },
        ],
        action: 'all',
        resource: 'invoiceList',
        auth_require: true,
      },
    },
    {
      path: '/product-operations',
      name: 'product-operations',
      component: () => import('@/views/products/operation.vue'),
      meta: {
        pageTitle: 'Ürün İşlemleri',
        breadcrumb: [
          {
            text: 'Ürün İşlemleri',
            active: true,
          },
        ],
        action: 'all',
        resource: 'customer',
        auth_require: true,
      },
    },
    {
      path: '/user-create',
      name: 'user-create',
      component: () => import('@/views/user/create.vue'),
      meta: {
        pageTitle: 'Kullanıcı Ekle',
        breadcrumb: [
          {
            text: 'Kullanıcı Ekle',
            active: true,
          },
        ],
        action: 'all',
        resource: 'user',
        auth_require: true,
      },
    },
    {
      path: '/users',
      name: 'user-list',
      component: () => import('@/views/user/index.vue'),
      meta: {
        pageTitle: 'Kullanıcı Listesi',
        breadcrumb: [
          {
            text: 'Kullanıcı Listesi',
            active: true,
          },
        ],
        resource: 'user',
        action: 'all',
        auth_require: true,
      },
    },
    {
      path: '/invoices',
      name: 'invoice-list',
      component: () => import('@/views/invoice/index.vue'),
      meta: {
        pageTitle: 'Fiş Listesi',
        breadcrumb: [
          {
            text: 'Fiş Listesi',
            active: true,
          },
        ],
        resource: 'orders',
        action: 'all',
        auth_require: true,
      },
    },
    {
      path: '/allorders',
      name: 'allorders-list',
      component: () => import('@/views/order/AllOrder.vue'),
      meta: {
        pageTitle: 'Sipariş Listesi',
        breadcrumb: [
          {
            text: 'Sipariş Listesi',
            active: true,
          },
        ],
        resource: 'operation',
        action: 'all',
        auth_require: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/forgot-password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = store.getters['auth/IS_LOGIN']
  if (to.meta.auth_require) {
    // console.log(canNavigate(to), isLoggedIn)
    if (!canNavigate(to) || !isLoggedIn) {
      // Redirect to login if not logged in
      // if (!isLoggedIn) return next({ name: 'login' })
      return next({ name: 'login' })
      // If logged in => not authorized
      // return next({ name: 'login' })
    }
    // Redirect if logged in
    if (isLoggedIn) {
      // const userData = getUserData()
      // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
      return next()
    }
    return next({ name: 'login' })
  }
  return next()
})
export default router
