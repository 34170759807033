import { auth, firestore } from '@/libs/firebase'
// eslint-disable-next-line import/no-cycle
import request from '@/libs/axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  namespaced: true,
  state: {
    user: {},
    userInfo: {},
    is_auth: false,
    customer_permissions: [
      {
        subject: 'products',
        action: 'all',
      },
      {
        subject: 'orders',
        action: 'all',
      },
      {
        subject: 'invoiceList',
        action: 'all',
      },
      {
        subject: 'profile',
        action: 'all',
      },
    ],
    admin_permissions: [
      {
        subject: 'user',
        action: 'all',
      },
      {
        subject: 'customer',
        action: 'all',
      },
      {
        subject: 'operation',
        action: 'all',
      },
      {
        subject: 'orders',
        action: 'all',
      },
      {
        subject: 'featured',
        action: 'all',
      },
    ],
    guest_permissions: [
      {
        subject: 'guest',
        action: 'all',
      },
    ],
  },
  getters: {
    IS_LOGIN(state) {
      if (Object.keys(state.user).length > 1) return true
      return false
    },
    getUser(state) {
      return {
        user: state.user,
        userInfo: state.userInfo,
      }
    },
    getPermission(state) {
      return state[`${state.userInfo.role}_permissions`] || state.guest_permissions
    },
  },
  mutations: {
    SET_AUTH(state, user) {
      state.user = user
    },
    SET_USER_INFO(state, user) {
      state.userInfo = user
    },
    SET_LOGOUT(state) {
      state.userInfo = {}
      state.user = {}
    },
  },
  actions: {
    Login({
      commit, state, dispatch, getters,
    }, payload) {
      return auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(async userCredential => {
          // Signed in
          const { user } = await userCredential
          await commit('SET_AUTH', user)
          await auth.currentUser.getIdToken(true)
            .then(idToken => {
              request.defaults.headers.AuthToken = `${idToken}`
              localStorage.setItem('token', idToken)
              // console.log(request.defaults.headers)
            })
          const result = {
            success: null,
            message: null,
          }
          if (user.emailVerified) {
            result.success = true
            result.message = 'Başarılı Giriş'
            await dispatch('getUserInfo')
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$ability.update(getters.getPermission)
            if (state.userInfo.role === 'admin') {
              await router.push({ name: 'user-list' })
            } else {
              await router.push({ name: 'products' })
            }
            return result
          }
          result.success = false
          result.message = 'Giriş başarısız'
          return false
        })
        .catch(error => error)
    },
    Logout({ commit }) {
      auth.signOut().then(() => {
        commit('SET_LOGOUT')
        localStorage.removeItem('token')
        router.push({ name: 'login' })
      })
    },
    async getUserInfo({ commit, state }) {
      // console.log(state.user.uid)
      const user = await firestore.collection('users').doc(state.user.uid).get()
      // console.log(user)
      commit('SET_USER_INFO', user.data())
    },
    // eslint-disable-next-line no-empty-pattern
    async createUser({}, payload) {
      const response = await request.post('/user', payload)
      if (response.status === 201) {
        return response
      }
      return false
    },
  },
}
