import Firebase from 'firebase'
import Vue from 'vue'

const config = {
  apiKey: 'AIzaSyCxA-ooAKowMGj6bf2_Cwh4vN6CMyOFRSA',
  authDomain: 'medcup-a1268.firebaseapp.com',
  projectId: 'medcup-a1268',
  storageBucket: 'medcup-a1268.appspot.com',
  messagingSenderId: '611676386622',
  appId: '1:611676386622:web:1b9d51857e3a2d25c0a86f',
  measurementId: 'G-EGR8Q4BF6H',
}
let app
if (!Firebase.apps.length) {
  app = Firebase.initializeApp(config)
  Vue.prototype.$firestore = app.firestore()
  Vue.prototype.$fireauth = app.auth()
}
export const auth = app.auth() || Firebase.auth()
export const firestore = app.firestore() || Firebase.firestore()
